import { sub } from "date-fns";
import type { SubscriptionStatus } from "./server/entities/user_account/UserAccountEntity";

export const SUBSCRIPTION_DATE_RESTRICTION_DAYS_LOOKBACK = 30;
export type SubscriptionFeature = 'group.create'
                                    | 'group.join.request'
                                    | 'group.leaderboard.view'
                                    | 'activity.compare.others'
                                    | 'activity.view.lookback'
                                    | 'activity.stats.swolf'
                                    | 'activity.stats.stroke_count'
                                    | 'activity.stats.stroke_length'
                                    | 'activity.stats.stroke_balance'
                                    | 'activity.stats.trends'
                                    | 'discussion.create'
                                    | 'comment.create'
                                    | 'picture.upload'
                                    | 'profile.visible.public';

const SUBSCRIPTION_STATUS_RANK: {[key in SubscriptionStatus]: number} = {
    'freemium': 0,
    'trial': 1,
    'paying': 2
};

/*
 * Map that indicates the minimum subscription status required to activate a feature.
 * Subscription status ranks are defined in SUBSCRIPTION_STATUS_RANK: freemium < trial < paying 
 */
const FEATURE_ACTIVATION_START_MAP: {[key in SubscriptionFeature]: SubscriptionStatus} = {
    'group.create': 'paying',
    'group.join.request': 'freemium', // a little too harsh to require paying for this
    'group.leaderboard.view': 'trial',
    'activity.compare.others': 'trial',
    'activity.view.lookback': 'trial',
    'activity.stats.swolf': 'trial',
    'activity.stats.stroke_count': 'trial',
    'activity.stats.stroke_length': 'trial',
    'activity.stats.stroke_balance': 'trial',
    'activity.stats.trends': 'trial',
    'discussion.create': 'paying',
    'comment.create': 'freemium',
    'picture.upload': 'paying',
    'profile.visible.public': 'paying',
};

export function is_feature_enabled(feature: SubscriptionFeature, subscription_status: SubscriptionStatus): boolean {

    if (subscription_status === undefined) {
        return false;
    }

    // subscription_status = 'paying';
    let activation_start_status = FEATURE_ACTIVATION_START_MAP[feature];
    let feature_status_rank = SUBSCRIPTION_STATUS_RANK[activation_start_status];
    let subscription_status_rank = SUBSCRIPTION_STATUS_RANK[subscription_status];

    // console.log("----------> ", feature_status_rank, subscription_status_rank, feature, subscription_status);

    // console.log("--------> ", 
    //             feature, 
    //             subscription_status, 
    //             feature_status_rank, 
    //             subscription_status_rank, 
    //             subscription_status_rank >= feature_status_rank);

    return subscription_status_rank >= feature_status_rank;
}

export function is_feature_enabled_activity_accessible(feature: Extract<SubscriptionFeature, 'activity.view.lookback'>, 
                                                        subscription_status: SubscriptionStatus,
                                                        activity_creation_timestamp: Date,
                                                        viewer_user_uuid: string,
                                                        owner_user_uuid: string): boolean {
        
    let feature_enabled = is_feature_enabled(feature, subscription_status);
    let within_date_threshold = activity_creation_timestamp.getTime() >= sub(new Date(), {days: SUBSCRIPTION_DATE_RESTRICTION_DAYS_LOOKBACK}).getTime();
    let own_activity = viewer_user_uuid === owner_user_uuid;

    // console.log("----------> ", feature_enabled, within_date_threshold, feature, subscription_status, activity_creation_timestamp);

    return feature_enabled || (within_date_threshold && own_activity);
}